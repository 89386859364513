$pc: 768px; // PC
$tab: 767px; // タブレット
$pc-width: 1280px;
$main-font: "Helvetica Neue", "Noto Sans JP", "Segoe UI",
  "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
$alphabet-font: "Oswald", sans-serif;
//明朝体の場合
//"Noto Serif JP", serif;
$container-padding-hori: 5%; //.l-containerの水平方向のpadding
$container-padding-hori-left: 13%;
$container-max-width: 1080px; //デフォルトのインナー幅の最大幅
$main-color: #005cab;
$sub-color-1: #fff7f2;
$font-color: #333;
$z-index-max: 9999; //z-indexの最大値を設定

@mixin pc {
  @media (min-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

@mixin hover {
  @media (hover) {
    &:hover {
      @content;
    }
  }
}

@mixin hover-default {
  transition: opacity 0.3s;

  @include hover {
    opacity: 0.6;
  }
}

@mixin clear {
  display: block;
  clear: both;
  content: "";
}

@mixin container-max-width($max-width) {
  //インナー幅とコンテンツ幅の余白を元に、コンテンツ幅のmax-widthを返す
  max-width: calc(
    #{$max-width} + #{$container-padding-hori} + #{$container-padding-hori-left}
  );
}

// @mixin container-max-width-top($max-width) {
//   //インナー幅とコンテンツ幅の余白を元に、コンテンツ幅のmax-widthを返す
//   max-width: calc(#{$max-width} + #{$container-padding-hori} * 2);
// }

//カードレイアウト
@mixin cards(
  $column-num: 1,
  //カラムの数＝一行につき、何個子要素があるか
  $row-gap: 0px,
  //縦の余白(calc()のため、0でも単位が必要)
  $column-gap: 0px //横の余白(calc()のため、0でも単位が必要)
) {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  $column-num-plus1: $column-num + 1;

  @if $column-num != 1 {
    margin-right: calc(#{$column-gap} / 2 * -1);
    margin-left: calc(#{$column-gap} / 2 * -1);
  }

  &--item {
    @if $column-num == 1 {
      width: 100%;
    } @else {
      width: calc((100% - #{$column-gap} * #{$column-num}) / #{$column-num});
      margin-right: calc(#{$column-gap} / 2);
      margin-left: calc(#{$column-gap} / 2);
    }

    &:nth-of-type(n + #{$column-num-plus1}) {
      //$column-numをそのまま使うとうまく動かないので、別の変数を使う
      margin-top: $row-gap;
    }
  }
}

@mixin list-none {
  list-style: none;
  padding-left: 0;
}

@mixin objectfit {
  object-fit: cover;
  font-family: "object-fit:cover;";
}

@mixin center-center {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

@mixin center-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin center-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin center-right {
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
}

@mixin center-left {
  position: absolute;
  bottom: 0;
  top: 0;
  margin: auto;
}

@mixin arrow-right {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  content: "";
}

@mixin arrow-bottom {
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  position: absolute;
  content: "";
}

@mixin arrow-left {
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: absolute;
  content: "";
}
