.p-top-cm {
  position: relative;

  > .l-container {
    @include container-max-width($container-max-width);
  }

  &--inner {
    padding: 60px 0;
    @include pc {
      padding: 80px 0;
    }
  }

  &--top {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 75px;
    @include pc {
      gap: 80px;
      margin-bottom: 87px;
    }

    &--image {
      width: 38.92%;
      @include pc {
        width: 100%;
        max-width: 300px;
      }
    }

    &--message {
      color: #333;
      font-size: 13px;
      font-weight: 500;
      line-height: 1.5;
      @include pc {
        font-size: 25px;
      }
    }
  }

  &--bottom {
    max-width: 600px;
    text-align: center;
    margin: 0 auto;

    &--heading {
      display: flex;
      align-items: center;
      color: $main-color;
      font-size: 19px;
      font-weight: 500;
      margin-bottom: 14px;
      @include pc {
        font-size: 25px;
        margin-bottom: 20px;
      }

      &::before,
      &::after {
        content: "";
        height: 1px;
        flex-grow: 1;
        background-color: $main-color;
      }
      &::before {
        margin-right: 1rem;
      }
      &::after {
        margin-left: 1rem;
      }
    }

    &--content {
      &--wrapper {
        width: 100%;
        aspect-ratio: 16 / 9;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    &--image {
      width: 100%;
      max-width: 596px;
    }
  }
}
