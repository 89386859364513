.l-header-simple {
    background:#fff;
    left: 0;
    letter-spacing: .05em;
    position: relative;
    top: 0;
    width: 100%;
    z-index: $z-index-max - 4;

    @include tab {
        padding: 16px 0;
    }

    @include pc {
        min-width: $pc-width;
    }

    a {
        transition: opacity .3s;

        @include pc {
            &:hover {
                opacity: .6;
            }
        }
    }

    & > .l-container {
        @include pc {
            max-width: none;
        }
    }

    &--logo {
        &--wrapper {
            font-size: 0;

            @include pc {
                margin: 10px 0;
            }
        }

        display: inline-block;

        img {
            display: block;
            height: auto;
            max-width: 100%;
        }
    }

    &.js-header-sidebar {
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
        
        @include pc {
            height: 100vh;
            min-width: 0;
            width: 181px;
        }

        & > .l-container {
            @include pc {
                padding-top: 74px;
            }
        }

        .l-header-simple--logo--wrapper {
            @include pc {
                text-align: center;
            }
        }
    }
}