/*非表示にするクラス(スクリーンリーダー用のテキストなどにあてる。)*/
.u-hidden {
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
    height: 1px !important;
    overflow: hidden !important;
    border: 0 !important;
    padding: 0 !important;
    clip: rect(0 0 0 0) !important;
    margin: -1px !important;
}

.u-md-hidden {
    @include pc {
        position: absolute !important;
        white-space: nowrap !important;
        width: 1px !important;
        height: 1px !important;
        overflow: hidden !important;
        border: 0 !important;
        padding: 0 !important;
        clip: rect(0 0 0 0) !important;
        margin: -1px !important;
    }
}

.u-sm-hidden {
    @include tab {
        position: absolute !important;
        white-space: nowrap !important;
        width: 1px !important;
        height: 1px !important;
        overflow: hidden !important;
        border: 0 !important;
        padding: 0 !important;
        clip: rect(0 0 0 0) !important;
        margin: -1px !important;
    }
}