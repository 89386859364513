*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box
}

html {
  font-size: 52%;


  @include pc {
    font-size: 62.5%;
  }

  &.js-scroll-prevent {
    overflow: hidden;
  }
}

body{
  letter-spacing: 0.07em;
  font-family: $main-font;
  font-size: 16px;
  width: 100%;

  @include tab {
    overflow-x: hidden;
  }

  @include pc{
    width: 100%;
    min-width: $pc-width;
  }
}

h1 {
  margin: 0;
}

p{
  font-size: 16px;
  line-height: 1.8;
}

iframe,
img,
svg {
  height: auto;
  max-width: 100%;
}


b, strong {
  font-weight: bold;
}

iframe,
img {
  vertical-align: bottom;
}

dl{
  margin: 0;
}

button, [type="button"], [type="reset"], [type="submit"] {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
    font-weight: inherit;
    
  outline: none;
  padding: 0;
  white-space: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.menu{
  list-style: none;
  padding-left: 0;
  @include pc{
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: flex-start;
  }
}

.list-none{
  @include list-none;
}