.p-top-publication-results {
  position: relative;
  background: #f2f2f2;

  &--inner {
    padding: 60px 0;
    @include pc {
      padding: 105px 0;
    }
  }

  &--heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 46px;

    &--main {
      color: $main-color;
      font-family: $alphabet-font;
      font-size: 27px;
      font-weight: 500;
      @include pc {
        font-size: 36px;
      }
    }

    &--sub {
      color: #333;
      font-size: 12px;
      font-weight: normal;
      @include pc {
        font-size: 15px;
      }
    }
  }
}
