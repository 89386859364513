.c-pagination-3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    transition: background, color;
    transition-duration: 0.3s;
    svg path,
    svg text {
      //svg path が fill="none" の場合は fill : currentColor; を、 stroke="none"の場合はstroke : currentColor;をコメントアウト
      fill: currentColor;
      // stroke : currentColor;
    }
    @include hover {
      color: #fff;
      background: $main-color;
    }
  }
  .page-numbers {
    display: none; // 数字の項を非表示にする為
    color: $main-color;
    font-size: 10px;
    font-weight: normal;
    margin: 0 5px; //margin-buttomはアーカイブのscssファイルで設定(ページネーションの有無で余白が変わらないようにするため)
    padding: 7px 10px;
    border-radius: 10px;
    min-height: 37px;
    min-width: 37px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: $sub-color-1;
    @include pc {
      font-size: 20px;
      padding: 14px 23px;
    }

    &.current {
      color: #fff;
      background: $main-color;
    }

    &.dots {
      background: none;
    }

    &.next,
    &.prev {
      display: flex;
      // font-weight: 700;
      &:not(:hover) {
        background: #f2f2f2;
      }
    }

    &.next {
      .page-numbers--inner {
        padding-right: 8px;
        letter-spacing: 0;
      }
      @include pc {
        margin-left: 10px;
      }
    }
    &.prev {
      .page-numbers--inner {
        padding-left: 8px;
        letter-spacing: 0;
      }
      @include pc {
        margin-right: 10px;
      }
    }

    .page-numbers--suffix,
    .page-numbers--prefix {
      position: relative;
      top: -1px;
    }
  }
}
