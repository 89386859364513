.p-our-results {
  .l-mainvisual {
    background: url("../img/our-results/bg--our-results--sp.jpg") no-repeat
      center center / cover;
    @include pc {
      background-image: url("../img/our-results/bg--our-results--pc.jpg");
    }
  }

  &--contents {
    &--item {
      display: block;
      padding: 59px 0;
      @include pc {
        display: flex;
        align-items: flex-start;
        gap: 35px;
        padding: 96px 0;
      }
      &:last-child {
        padding-bottom: 0;
        @include pc {
          padding-bottom: 35px;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #989898;
      }

      &--figure {
        background: #dfdfdf;
        width: 100%;
        // min-height: 190px;
        max-width: 450px;
        flex-grow: 1;
        margin-bottom: 46px;
        @include pc {
          width: 41.6%;
          // min-height: 265px;
          margin-bottom: 0;
        }

        img {
          width: 100%;
          object-fit: cover;
          aspect-ratio: 305 / 190;
        }
      }

      &--right {
        width: 100%;
        @include pc {
          width: 58.4%;
        }
      }

      &--heading {
        color: $main-color;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.26;
        margin-bottom: 24px;
        @include pc {
          font-size: 36px;
        }
      }

      &--text {
        font-size: 13px;
        font-weight: 300;
        line-height: 1.54;
        @include pc {
          font-size: 14px;
          line-height: 2.14;
        }
      }
    }

    &--conversion {
      margin-top: 59px;
      margin-bottom: 59px;
      @include pc {
        margin-top: 96px;
        margin-bottom: 96px;
      }

      &--button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f4faff;
        border-bottom: 2px solid $main-color;
        text-decoration: none;
        padding: 12px 8px;
        @include pc {
          padding: 24px 12px;
        }

        @include hover {
          transition: all 0.3s;
          opacity: 0.6;
        }

        &--heading {
          color: $main-color;
          font-size: 15px;
          font-weight: normal;
          @include pc {
            font-size: 25px;
            font-weight: 500;
          }
        }

        &--prefix {
          color: $main-color;
          font-size: 10px;
          font-weight: normal;
          @include pc {
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
