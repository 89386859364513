.c-button-1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $main-color;
  width: 100%;
  max-width: 245px;
  text-decoration: none;
  cursor: pointer;

  @include hover {
    .c-button-1--icon {
      animation-name: arrow;
      animation-duration: 0.4s;
      animation-timing-function: cubic-bezier(0.65, 0.01, 0.3, 0.97);
    }
  }

  &.-white {
    background: #fff;

    .c-button-1--heading {
      color: #333;
    }

    .c-button-1--icon {
      &::after {
        background-image: url("../img/common/button-1--arrow--blue.png");
      }
    }
  }

  &--heading {
    color: #fff;
    font-size: 13px;
    font-weight: normal;
    text-align: center;
    padding: 16px 15px;
    @include pc {
      font-size: 15px;
      padding: 18px 15px;
    }
  }

  &--icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 18px;

    &::after {
      position: absolute;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      background: url("../img/common/button-1--arrow.png") no-repeat center center / contain;
      width: 100%;
      height: 5px;
      max-width: 18px;
    }

    img {
      width: 100%;
      max-width: 18px;
    }
  }
}

@keyframes arrow {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  49.9% {
    transform: translateX(50%);
    opacity: 0;
  }
  50% {
    transform: translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
