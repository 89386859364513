.p-contact-1 {
  a {
    transition: opacity 0.3s;

    @include pc {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  // .l-container {
  //     @include pc {
  //         @include container-max-width(800px);
  //     }
  // }

  &--heading {
    &--wrapper {
      background: #f7f8fc;
      padding: 70px 0 80px;

      @include pc {
        padding-top: 138px;
        padding-bottom: 78px;
      }
    }

    color: #0d2b55;
    font-size: 4.2rem;
    font-weight: normal;
    line-height: 1.6;
  }

  &--catch {
    padding: 60px 0;

    @include pc {
      padding: 97px 0 89px;
    }

    p {
      font-size: 13px;
      font-weight: 300;
      line-height: 1.94;
      @include pc {
        font-size: 18px;
      }
    }
  }

  &--form--wrapper {
    padding-bottom: 59px;

    @include pc {
      padding-bottom: 96px;
    }

    .c-form {
      span.wpcf7-list-item.first {
        margin-top: 5px;
      }

      span.wpcf7-list-item.last {
        margin-bottom: 5px;
      }

      &--item {
        margin-bottom: 20px;
        @include pc {
          display: flex;
          align-items: baseline;
          gap: 40px;
          margin-bottom: 47px;
        }

        input,
        textarea,
        select {
          background: #f2f2f2;
          border: 1px solid #d3d3d3;
          border-radius: 3px;
          padding: 12px 5px;
          width: 100%;

          // @include pc {
          //   padding-right: 5px;
          //   padding-left: 5px;
          // }
        }

        textarea {
          height: 80px;
        }

        select {
          background-image: url(../img/contact/contact-1_icon_arrow.svg);
          background-position: center right 5px;
          background-repeat: no-repeat;

          @-moz-document url-prefix() {
            padding: 15px 1px;
          }

          @include pc {
            background-position: center right 10px;
          }
        }
      }

      &--item--heading {
        color: #0d2b55;
        font-size: 14px;
        line-height: 1.6;
        margin-bottom: 10px;
        @include pc {
          margin-bottom: 0;
        }

        &--required {
          background: $main-color;
          border-radius: 7px;
          color: #fff;
          display: inline-flex;
          align-items: center;
          font-size: 10px;
          line-height: 1;
          margin-left: 7px;
          padding: 0 7px;
          text-align: center;

          &.-option {
            background: #fff;
            color: $main-color;
          }
        }
      }

      &--item--explanation {
        margin-bottom: 2px;

        p {
          color: #295cba;
          font-size: 10px;
        }
      }

      &--item--list {
        @include pc {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }

      &--item--item {
        @include pc {
          width: calc((100% - 8px) / 2);
        }

        &:nth-of-type(odd) {
          @include tab {
            margin-bottom: 8px;
          }
        }
      }

      &--item-notice {
        margin: 20px 0 0;

        p {
          color: #0d2b55;
          font-size: 15px;
          line-height: 1.5;
        }

        a {
          color: #295cba;
          text-decoration: underline;
        }
      }

      &--item-submit {
        flex-direction: column;
        align-items: center;
        gap: 22px;
        margin: 33px auto 0;

        .c-button-1 {
          margin: 0 auto 10px;
          @include pc {
            margin-bottom: 0px;
          }
        }

        .wpcf7-spinner {
          display: none;
        }

        p {
          font-size: 10px;
          font-weight: normal;
          text-align: right;
          @include pc {
            text-align: center;
          }
        }

        input[type="submit"] {
          display: block;
          background: none;
          border: none;
          padding: 16px 15px;
          margin: auto;
        }
      }
    }
  }
}

/*safariのみに適用*/
::-webkit-full-page-media,
:future,
:root .p-contact-1 .p-contact-1--form--wrapper .c-form--item select {
  padding-top: 11px;
  padding-bottom: 11px;
}

::-webkit-full-page-media,
:future,
:root .p-contact-1 .p-contact-1--form--wrapper .c-form--item input[type="submit"] {
  &:focus {
    border-color: #4d68a6;
    outline: none;
  }
}
