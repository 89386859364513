.p-top-our-ambition {
  position: relative;
  background: url("../img/top/our-ambition/bg--top--our-ambition--sp.jpg")
    no-repeat center center / cover;
  @include pc {
    background-image: url("../img/top/our-ambition/bg--top--our-ambition--pc.jpg");
  }

  &--tag {
    position: absolute;
    padding-top: 80px;
    margin-top: -80px;
  }

  > .l-container-top {
    position: relative;
    padding: 0 $container-padding-hori 0 $container-padding-hori-left;
    @include pc {
      padding: 0 $container-padding-hori;
    }
  }

  .l-sidetitle {
    @include pc {
      left: 2%;
    }

    &--heading {
      &--main {
        color: #fff;
      }
      &--prefix {
        background: #fff;
      }
    }
  }

  &--inner {
    padding: 74px 0;
    @include pc {
      padding: 178px 0 176px;
      max-width: 500px;
    }
  }

  &--heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #fff;
    margin-bottom: 47px;

    &--main {
      color: inherit;
      font-family: $alphabet-font;
      font-size: 27px;
      font-weight: 500;
      @include pc {
        font-size: 36px;
      }
    }

    &--sub {
      color: inherit;
      font-size: 12px;
      font-weight: normal;
      @include pc {
        font-size: 15px;
      }
    }
  }

  &--message {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.89;
    margin-bottom: 25px;
    @include pc {
      font-size: 25px;
    }
  }

  &--description {
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.54;
    @include pc {
      font-size: 18px;
      line-height: 1.94;
    }
  }

  &--bg {
    display: none;
    overflow: hidden;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 42%;
    max-width: 600px;
    @include pc {
      display: block;
    }
  }
}
