.l-cta-common {
  background: #f4faff;
  @include pc {
    background: url("../img/common/bg--cta.jpg") no-repeat center center / cover;
  }

  .l-container,
  .l-container-top {
    display: flex;
    justify-content: flex-end;
  }

  .l-container {
    padding: 0 12%;
    @include pc {
      padding: 0 5% 0 13%;
    }
  }

  &--inner {
    width: 100%;
    padding: 59px 0 74px;
    @include pc {
      width: 50%;
      padding: 80px 0;
    }
  }

  &--heading {
    display: block;
    margin-bottom: 40px;

    &--main {
      display: block;
      color: $main-color;
      font-family: $alphabet-font;
      font-size: 27px;
      font-weight: 500;
      margin-bottom: 12px;
      @include pc {
        font-size: 36px;
      }
    }

    &--sub {
      display: block;
      color: #000;
      font-size: 12px;
      @include pc {
        font-size: 15px;
      }
    }
  }

  &--message {
    color: #000;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.42;
    margin-bottom: 32px;
    @include pc {
      font-size: 14px;
      margin-bottom: 73px;
    }
  }

  &--button--wrapper {
    display: flex;
    justify-content: center;
    @include pc {
      justify-content: flex-end;
    }
  }
}
