.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.alignright {
  display: block;
  margin-left: auto;
}
.alignleft {
  display: block;
  margin-right: auto;
}
