.l-footer-1 {
  background-color: #474747;
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0.05em;

  @include tab {
    // text-align: center;
  }

  &--wrapper {
    @include pc {
      min-width: 1280px;
    }
  }

  .l-container-top {
    max-width: none;
  }

  @include pc {
    @media all and (-ms-high-contrast: none) {
      overflow: hidden;
    }
  }

  a {
    @include hover-default;
  }

  &--upper {
    padding: 40px 0 0;

    @include pc {
      padding-top: 80px;
      padding-bottom: 55px;
    }

    &--inner {
      @include pc {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    &--left {
      position: relative;
      @include pc {
        margin-right: 53px;
        width: 14.6%;
        left: 0;
      }
    }

    &--right {
      @include pc {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &--lower {
    padding: 35px 0 62px;
    @include pc {
      padding-bottom: 85px;
    }
  }

  &--logo {
    &--wrapper {
      font-size: 0;

      img {
        width: 100%;
        max-width: 96px;
        @include pc {
          max-width: 175px;
        }
      }
    }

    display: inline-block;

    img {
      display: block;
    }
  }

  &--address {
    &--list {
      list-style: none;
      padding: 0;

      @include pc {
        padding-left: 4px;
      }
    }

    font-style: normal;
    line-height: 1.2;

    &--code {
      display: inline-block;
      margin: 15px 0 6px;
    }
  }

  &--menu--container {
    ul {
      display: flex;
      flex-wrap: wrap;
      width: 80%;
      margin: 0;
      padding-top: 40px;
      padding-left: 24px;

      @include pc {
        flex-wrap: nowrap;
        width: auto;
        // border-right: 1px solid #fff;
        margin: 13px 0;
        padding-top: 0;
        padding-left: 0;
      }
    }
    .menu-item {
      width: 50%;
      text-transform: capitalize;

      @include tab {
        // margin: 30px 0;
        margin-bottom: 10px;
      }

      @include pc {
        width: auto;
      }

      a {
        color: inherit;
        display: block;
        font-size: 13px;
        line-height: 1.3;
        text-decoration: none;
        @include pc {
          font-size: 14px;
        }
      }

      &:not(:first-child) {
        @include pc {
          margin-left: 30px;
        }
      }

      &:last-child {
        @include pc {
          // margin-right: 35px;
        }
      }
    }
  }

  &--contact {
    &--wrapper {
      line-height: 1;

      @include pc {
        margin: 13px 0 13px 35px;
      }
    }

    color: inherit;
    line-height: 1.3;
    text-decoration: none;
    text-transform: capitalize;

    svg {
      margin-right: 9px;
    }
  }

  &--copyright {
    font-size: 10px;
    @include pc {
      font-size: 13px;
    }
  }
}
