// absolute
.u-position-absolute {
    position: absolute !important;
}

.u-position-sm-absolute {
    @include tab {
        position: absolute !important;
    }
}

.u-position-md-absolute {
    @include pc {
        position: absolute !important;
    }
}

// fixed
.u-position-fixed {
    position: fixed !important;
}

.u-position-sm-fixed {
    @include tab {
        position: fixed !important;
    }
}

.u-position-md-fixed {
    @include pc {
        position: fixed !important;
    }
}
