.l-mainvisual {
  &--inner {
    padding: 105px 0 87px;
    @include pc {
      padding: 200px 0 100px;
    }
  }

  &--heading {
    display: block;
    color: $main-color;
    &--main {
      display: block;
      font-family: $alphabet-font;
      font-size: 48px;
      margin-bottom: 6px;
      @include pc {
        font-size: 80px;
      }
    }
    &--sub {
      display: block;
      font-size: 15px;
      @include pc {
        font-size: 30px;
      }
    }
  }
}
