.l-header-1 {
  background: none;
  left: 0;
  letter-spacing: 0.05em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-max - 6;

  .l-container-top {
    max-width: none;
    @include tab {
      padding: 16px 5%;
    }
  }

  @include pc {
    background: none;
    min-width: $pc-width;
  }

  &.is-open {
    background: $main-color;
  }

  &.-change-color {
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transition: all 1s;
  }

  & > .l-container-top {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @include tab {
      justify-content: space-between;
    }

    @include pc {
      // max-width: 1280px;
      // padding-right: 26px;
      // padding-left: 26px;
      // padding-top: 26px;
    }
  }

  &--right {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    @include pc {
      margin-left: auto;
    }
  }

  &--sp-logo {
    max-width: 109px;
    font-size: 0;
    padding: 0 16px;
    margin-bottom: 130px;

    img {
      max-width: 109px;
    }
  }

  &--menu--container .menu-item a,
  &--contact--item a {
    @include pc {
      position: relative;
    }

    &:before {
      @include pc {
        background: $main-color;
        bottom: 0;
        content: "";
        height: 1px;
        left: 5%;
        position: absolute;
        transform: scale(0, 1);
        transform-origin: right top;
        transition: transform 0.3s;
        width: 90%;
      }
    }

    @include hover {
      @include pc {
        &:before {
          transform: scale(1, 1);
          transform-origin: left top;
        }
      }
    }
  }

  &--logo {
    &--wrapper {
      max-width: 109px;
      font-size: 0;

      @include pc {
        max-width: 244px;
        margin: 20px 0;
      }
    }

    display: inline-block;
    @include hover-default;

    img {
      display: block;
    }
  }

  &--menu {
    &--container {
      @include tab {
        background: $main-color;
        // display: none;
        height: 100%;
        left: 0;
        min-height: 100vh;
        overflow: scroll;
        overflow-x: hidden;
        padding: 28px 0 60px;
        top: 0;
        width: 100%;
        z-index: $z-index-max - 5;
        -webkit-overflow-scrolling: touch;
        transform: translateX(100%);
        transition: all 0.3s;

        &.visible {
          transform: translateX(0);
        }
      }

      .menu {
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        @include pc {
          padding-right: 30px;
        }
      }

      .menu-item {
        @include tab {
          // background: #efefef;
          // border-bottom: 1px solid #000;
        }
        a {
          color: #fff;
          display: block;
          font-size: 18px;
          font-weight: normal;
          padding: 15px 16px;
          text-decoration: none;

          @include tab {
            line-height: 1.6;
          }

          @include pc {
            color: #000;
            font-size: 18px;
            font-weight: normal;
            line-height: 1.2;
            padding: 20px 10px;
            margin: 0 20px;
          }
        }

        & > a {
          // background: #ffffff;
        }

        &:first-child,
        &:last-child {
          .sub-menu {
            @include pc {
              transform: translateX(0);
            }
          }
        }

        &:first-child {
          .sub-menu {
            @include pc {
              left: 0;
            }
          }
        }

        &:last-child {
          .sub-menu {
            @include pc {
              left: auto;
              right: 0;
            }
          }
        }
      }

      .menu-item-has-children {
        @include pc {
          position: relative;
        }
        & > a {
          @include tab {
            background: #ffffff;
            position: relative;
            width: calc(100% - 70px);
          }

          &:before,
          &:after {
            @include tab {
              background: #000;
              border-radius: 1px;
              content: "";
              height: 2px;
              pointer-events: none;
              position: absolute;
              right: -41px;
              top: 50%;
              transform: translateY(-50%);
              width: 9px;
            }
          }

          &:after {
            @include tab {
              transform: translateY(-50%) rotate(90deg);
              transform-origin: center;
              transition: transform 0.3s;
            }
          }
        }
        &.js-active {
          & > a {
            &:after {
              @include tab {
                transform: translateY(-50%) rotate(0);
              }
            }
          }
        }

        &:hover {
          .sub-menu {
            @include pc {
              display: block !important;
            }
          }
        }
      }

      .sub-menu {
        list-style: none;
        display: none;
        padding: 0;
        cursor: pointer;

        @include pc {
          box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          width: 200px;
          padding: 0;
          z-index: 1;
        }

        a {
          font-weight: normal;
          @include tab {
            padding-right: 75px;
            padding-left: 48px;
          }
        }

        .menu-item {
          @include tab {
            border-bottom: none;
          }

          @include pc {
            display: block;
            margin: 0;
            min-width: 160px;
          }

          &:not(:last-child) {
            @include pc {
              border-bottom: 1px solid #eee;
            }
          }

          a {
            @include pc {
              align-items: center;
              display: flex;
              justify-content: center;
              text-align: center;
              width: 100%;
              height: 100%;
              padding: 16px 8px;

              @media all and (-ms-high-contrast: none) {
                display: inline-block;
              }

              br,
              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  &--contact {
    &--list {
      list-style: none;
      margin: 0;
      padding: 0;

      @include tab {
        margin-bottom: 100px;
      }

      @include pc {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        padding-left: 27px;
        position: relative;

        @media all and (-ms-high-contrast: none) {
          overflow: hidden;
        }
      }

      // &:before {
      //   @include pc {
      //     background: #000000;
      //     content: "";
      //     height: 19px;
      //     left: 0;
      //     position: absolute;
      //     top: 50%;
      //     transform: translateY(-50%);
      //     width: 1px;
      //   }
      // }
    }
    &--item {
      @include tab {
        // border-bottom: 1px solid #000;
      }

      a {
        align-items: center;
        color: inherit;
        display: flex;
        flex-wrap: wrap;
        font-size: 18px;
        font-weight: normal;
        padding: 15px 16px;
        text-decoration: none;

        @include tab {
          color: #fff;
          line-height: 1.6;

          @media all and (-ms-high-contrast: none) {
            height: 73px;
          }
        }

        @include pc {
          color: #fff;
          font-size: 18px;
          font-weight: normal;
          line-height: 1.2;
          padding: 13px 30px;
          position: relative;
          background: $main-color;
          transition: all 0.3s;

          @include hover {
            opacity: 0.6;
          }

          @media all and (-ms-high-contrast: none) {
            height: 80px;
          }
        }
      }

      svg {
        height: auto;
        margin-right: 9px;
        position: relative;

        @media all and (-ms-high-contrast: none) {
          top: -2px;
        }

        @include tab {
          width: 17px;
        }

        @include pc {
          width: 17px;
        }
      }

      &-mail {
        svg {
          path {
            fill: #000;
          }
        }
      }
    }
  }

  &--hamburger {
    // background: #fff;
    position: relative;
    display: inline-block;
    text-decoration: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    cursor: pointer;
    z-index: 9999;
    width: 50px;
    height: 50px;
    margin: auto;
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &--line {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 30px;
      height: 2px;
      background-color: #0b0b0b;
      -webkit-transition: inherit;
      transition: inherit;

      &:before,
      &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background-color: inherit;
        content: "";
        -webkit-transition: inherit;
        transition: inherit;
      }

      &:before {
        top: -10px;
      }

      &:after {
        top: 10px;
      }
    }

    &--text-hidden {
      position: absolute;
      white-space: nowrap;
      width: 1px;
      height: 1px;
      overflow: hidden;
      border: 0;
      padding: 0;
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      margin: -1px;
    }

    &[aria-expanded="true"] {
      .l-header-1--hamburger--line {
        background: transparent;
        &:before,
        &:after {
          top: 0;
          background-color: #fff;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
