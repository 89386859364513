// text-transform
.u-text-transform-none {
    text-transform: none !important;
}

.u-text-transform-capitalize {
    text-transform: capitalize !important;
}

.u-text-transform-uppercase {
    text-transform: uppercase !important;
}

.u-text-transform-lowercase {
    text-transform: lowercase !important;
}

.u-text-transform-full-width {
    text-transform: full-width !important;
}

.u-text-transform-md-none {
    @include pc {
        text-transform: none !important;
    }
}

.u-text-transform-md-capitalize {
    @include pc {
        text-transform: capitalize !important;
    }
}

.u-text-transform-md-uppercase {
    @include pc {
        text-transform: uppercase !important;
    }
}

.u-text-transform-md-lowercase {
    @include pc {
        text-transform: lowercase !important;
    }
}

.u-text-transform-md-full-width {
    @include pc {
        text-transform: full-width !important;
    }
}

.u-text-transform-sm-none {
    @include tab {
        text-transform: none !important;
    }
}

.u-text-transform-sm-capitalize {
    @include tab {
        text-transform: capitalize !important;
    }
}

.u-text-transform-sm-uppercase {
    @include tab {
        text-transform: uppercase !important;
    }
}

.u-text-transform-sm-lowercase {
    @include tab {
        text-transform: lowercase !important;
    }
}

.u-text-transform-sm-full-width {
    @include tab {
        text-transform: full-width !important;
    }
}