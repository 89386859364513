// archive-tab-none-1 archive-tab-1
.p-archive-1 {
  padding-bottom: 59px;

  @include pc {
    padding-bottom: 70px; //デザイン上の余白 - .page-numbers or button--wrapのmargin-bottom
  }

  &--button {
    background: #e3e3e3;
    border-radius: 3px;
    color: inherit;
    display: inline-block;
    letter-spacing: 0.05em;
    line-height: 1.3;
    max-width: 320px;
    text-decoration: none;
    text-align: center;
    padding: 9px 16px;
    width: 100%;
    &--wrap {
      text-align: center;
    }
  }

  &--button--wrap,
  &--articles,
  .page-numbers {
    margin-bottom: 10px;
  }

  &--articles--item {
    border-bottom: 1px solid #c4c4c4;

    a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 20px 0 15px;
      color: inherit;
      text-decoration: none;
      @include hover-default;
      @include pc {
        padding: 40px 38px 30px 0;
        flex-wrap: nowrap;
      }
    }
    &--date {
      margin-right: 10px;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.05em;
      @include tab {
        margin-bottom: 5px;
      }
      @include pc {
        width: 7.5%;
        margin-right: 28px;
      }
    }
    &--category--list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none;
      @include pc {
        max-width: 100px;
        width: 13.9%;
        margin-right: 25px;
        flex-direction: column;
        align-items: center;
      }
    }
    &--category--item {
      padding: 4px 8px;
      font-size: 10px;
      color: #fff;
      background: #000;
      border-radius: 1px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      text-align: center;

      @include tab {
        margin-right: 8px;
        margin-bottom: 8px;
      }

      &:not(:last-of-type) {
        @include pc {
          margin-bottom: 8px;
        }
      }
    }

    &--heading {
      width: 100%;
      color: $main-color;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.05em;
      line-height: 1.6;
      @include pc {
        flex: 1;
      }
    }
  }

  &--pagination--wrap {
    margin-top: 59px;
    @include pc {
      margin-top: 60px;
    }
  }

  //tabがある場合のの記載
  &--tab {
    padding-top: 59px;
    @include pc {
      padding-top: 96px;
    }
    &--wrap {
      margin-bottom: 40px;
      @include pc {
        margin-bottom: 80px;
      }
    }
  }
  &.-tab {
    .p-archive-1 {
      &--tab {
        &--wrap {
        }
      }
      //コンテンツの通常状態

      &--button {
        &--wrap {
          margin-top: 30px;
          @include pc {
            margin-top: 60px;
          }
        }
      }
    }
  }

  //カテゴリーページ、タクソノミーページについての記載
  &.-cat,
  &.-tax {
    .p-archive-1 {
      &--button {
        &--wrap {
          margin-top: 15px;
          @include pc {
            margin-top: 40px;
          }
        }
      }
    }
  }
}

//タブアニメーションの定義
@keyframes fade_ani {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
