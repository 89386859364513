.c-tab-1 {
  &--category-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // max-width: 702px;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    @include pc {
      gap: 16px;
    }
  }

  &--category--item {
    width: auto;
    // width: calc((100% - 10px) / 2);
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    @include tab {
      // &:nth-of-type(n + 3) {
      //   margin-top: 10px;
      // }
      // &:not(:nth-of-type(even)) {
      //   margin-right: 10px;
      // }
    }
    @include pc {
      // width: calc((100% - 18px * 3) / 4);
      width: auto;
      // margin-bottom: 10px;
      // &:not(:nth-of-type(4n)) {
      //   margin-right: 18px;
      // }
    }
  }

  //タブの通常状態
  &--category--item--inner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 8px 13px;
    color: #989898;
    font-size: 10px;
    font-weight: bold;
    border: 1px solid #989898;
    border-radius: 10px;
    overflow: hidden;
    letter-spacing: 0.05em;
    text-decoration: none;
    transition: 0.3s all;
    @include pc {
      font-size: 14px;
      padding: 11px 20px;
    }
    &:hover,
    &:focus,
    &[aria-selected="true"] {
      //アクティブ状態
      background: $main-color;
      color: #fff;
      border-color: $main-color;
    }
  }
}
