.l-container {
  margin: 0 auto;
  padding: 0 $container-padding-hori 0 $container-padding-hori-left;
  width: 100%;

  @include pc {
    @include container-max-width($container-max-width);
  }
}

.l-container-top {
  position: relative;
  margin: 0 auto;
  padding: 0 $container-padding-hori;
  width: 100%;

  @include pc {
    @include container-max-width($container-max-width);
  }
}
