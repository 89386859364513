//-----------------------------------------------------------------------------
// 見出しのアニメーション
//-----------------------------------------------------------------------------
.js-reveal {
  display: inline-block;
}
.js-reveal-ctt {
  display: inline-block;
  position: relative;
  // レイアウト調整
  padding-bottom: 5px;
}
.js-reveal-left .js-reveal-ctt {
  transform: scale(0, 1);
  transform-origin: left center;
}
.js-reveal-right .js-reveal-ctt {
  transform: scale(0, 1);
  transform-origin: right center;
}
.is-inviewed > .js-reveal-ctt {
  transform: scale(1, 1);
  transition: cubic-bezier(0.95, 0.05, 0.795, 0.035) 0.4s;
}
.js-reveal-mask {
  // background: linear-gradient(to right, #00b4ed 0%, #00469d 100%);
  background: $main-color;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.js-reveal-blue .js-reveal-mask {
  background: #005cab;
}
.js-reveal-black .js-reveal-mask {
  background: #000;
}
.js-reveal-white .js-reveal-mask {
  background: #fff;
}
.js-reveal-left .js-reveal-mask {
  transform-origin: right center;
}
.js-reveal-right .js-reveal-mask {
  transform-origin: left center;
}
.is-inviewed02 > .js-reveal-ctt > .js-reveal-mask {
  transition: cubic-bezier(0.19, 1, 0.22, 1) 0.4s;
  transform: scale(0, 1);
}

//-----------------------------------------------------------------------------
// 要素がスライドインするアニメーション
//-----------------------------------------------------------------------------
.slide-up {
  opacity: 0;
  transform: translate(0, 10px);
  transition: 1s;

  &.is-slide-up {
    opacity: 1;
    transform: translate(0, 0);
  }
}

//-----------------------------------------------------------------------------
// 画像を横からフィルターするアニメーション
//-----------------------------------------------------------------------------
.is-box-mask {
  animation: play 0.5s ease-in-out forwards;
  animation-timing-function: cubic-bezier(0.8, 0, 0.5, 1);
  position: relative;
  opacity: 1 !important;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    background: $main-color;
  }

  &::after {
    animation: maskOut 0.5s ease-in-out forwards 0.5s;
    content: "";
    position: absolute;
    top: 0;
    right: -1%;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $main-color;
  }
}

@keyframes maskOut {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(100%);
  }
}
@keyframes play {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
