.p-top-service {
  position: relative;

  &--tag {
    position: absolute;
    padding-top: 80px;
    margin-top: -80px;
  }

  &--inner {
    position: relative;
    padding: 60px 0 105px;
    @include pc {
      padding: 106px 0 135px;
    }
  }

  .l-sidetitle {
    top: -60px;
    @include pc {
      top: -106px;
      left: 2%;
    }
  }

  &--heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 46px;

    &--wrapper {
      position: relative;
      margin: 0 auto;
      padding: 0 $container-padding-hori 0 $container-padding-hori-left;
      width: 100%;

      @include pc {
        padding: 0 $container-padding-hori;
        @include container-max-width($container-max-width);
      }
    }

    &--main {
      color: $main-color;
      font-family: $alphabet-font;
      font-size: 27px;
      font-weight: 500;
      @include pc {
        font-size: 36px;
      }
    }

    &--sub {
      color: #333;
      font-size: 12px;
      font-weight: normal;
      @include pc {
        font-size: 15px;
      }
    }
  }

  &--list {
    display: flex;
    flex-direction: column;
    gap: 78px;
    list-style: none;
    padding: 0;
    margin: 0;
    @include pc {
      gap: 60px;
      flex-direction: row;
    }
  }

  &--item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    @include pc {
      width: calc(33.33% - 60px * 2 / 3);
    }

    &--inner {
      flex-grow: 1;
      background: #f2f2f2;
      padding: 22px 26px 24px;
    }

    &--image {
      height: 100%;
      max-height: 260px;
    }

    &--heading {
      color: $main-color;
      font-size: 23px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &--description {
      color: #333;
      font-size: 13px;
      font-weight: bold;
      line-height: 1.54;
      @include pc {
        font-size: 14px;
      }
    }

    &--button {
      &--wrapper {
        position: absolute;
        bottom: -36px;
        right: 8px;
        width: 170px;

        .c-button-1 {
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
