// text-align
.u-text-left {
    text-align: left !important;
}

.u-text-center {
    text-align: center !important;
}

.u-text-right {
    text-align: right !important;
}

.u-text-md-left {
    @include pc {
        text-align: left !important;
    }
}

.u-text-md-center {
    @include pc {
        text-align: center !important;
    }
}

.u-text-md-right {
    @include pc {
        text-align: right !important;
    }
}

.u-text-sm-left {
    @include tab {
        text-align: left !important;
    }
}

.u-text-sm-center {
    @include tab {
        text-align: center !important;
    }
}

.u-text-sm-right {
    @include tab {
        text-align: right !important;
    }
}