.p-news {
  .l-mainvisual {
    background: url("../img/news/bg--news--sp.jpg") no-repeat center center / cover;
    @include pc {
      background-image: url("../img/news/bg--news--pc.jpg");
    }
  }
}

// アーカイブページ用
.p-archive-1 {
  .l-mainvisual {
    background: url("../img/news/bg--news--sp.jpg") no-repeat center center / cover;
    @include pc {
      background-image: url("../img/news/bg--news--pc.jpg");
    }
  }
}
