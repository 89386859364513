.l-maincontents {
  position: relative;
}
.l-sidetitle {
  position: absolute;
  top: 0;
  left: 4%;
  transform: rotate(180deg);
  @include pc {
    left: 8%;
  }

  &--heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &--main {
      color: #989898;
      font-family: "Poppins", sans-serif;
      font-size: 11px;
      font-weight: 300;
      writing-mode: vertical-rl;
    }
    &--prefix {
      display: inline-block;
      height: 45px;
      width: 1px;
      background: #989898;
      @include pc {
        height: 150px;
      }
    }
  }
}
