.p-company {
  .l-mainvisual {
    background: url("../img/company/bg--company--sp.jpg") no-repeat center center / cover;
    @include pc {
      background-image: url("../img/company/bg--company--pc.jpg");
    }
  }

  &--message {
    &--inner {
      padding: 53px 0 59px;
      @include pc {
        padding: 97px 0 104px;
      }
    }

    &--heading {
      color: $main-color;
      font-size: 27px;
      font-weight: 500;
      margin-bottom: 42px;
      @include pc {
        font-size: 36px;
      }
    }

    &--text {
      color: #000;
      font-size: 13px;
      font-weight: 300;
      line-height: 1.54;
      @include pc {
        font-size: 18px;
        line-height: 1.94;
      }

      &:not(:last-child) {
        margin-bottom: 20px;
        @include pc {
          margin-bottom: 42px;
        }
      }
    }

    &--button--wrapper {
      display: flex;
      justify-content: flex-start;
      margin-top: 36px;
      @include pc {
        margin-top: 0;
        justify-content: flex-end;
      }
    }
  }

  &--about {
    &--inner {
      padding-bottom: 59px;
      @include pc {
        padding-bottom: 104px;
      }
    }

    &--heading {
      color: $main-color;
      font-size: 27px;
      font-weight: 500;
      margin-bottom: 6px;
      @include pc {
        font-size: 36px;
      }
    }

    &--table {
      &--row {
        display: block;
        color: #000;
        font-size: 14px;
        line-height: 1.8;
        border-bottom: 1px solid #364e62;
        padding: 20px 0;
        @include pc {
          display: flex;
          align-items: flex-start;
          padding: 38px 0;
        }
      }

      &--title {
        color: inherit;
        font-size: inherit;
        font-weight: normal;
        margin-bottom: 5px;
        @include pc {
          width: 12.2%;
          margin-bottom: 0;
        }
      }

      &--description {
        color: inherit;
        font-size: inherit;
        font-weight: 300;
        padding-left: 1em;
        @include pc {
          padding-left: 0;
        }
      }
    }
  }

  &--access {
    &--heading {
      color: $main-color;
      font-size: 27px;
      font-weight: 500;
      margin-bottom: 42px;
      @include pc {
        font-size: 36px;
      }
    }
  }
}
