.p-top-news {
  position: relative;

  > .l-container-top {
    margin: 0 auto;
    padding: 0 $container-padding-hori 0 $container-padding-hori-left;
    width: 100%;

    @include pc {
      padding: 0 $container-padding-hori;
      @include container-max-width($container-max-width);
    }
  }

  .l-sidetitle {
    @include pc {
      left: 2%;
    }
  }

  &--inner {
    padding: 60px 0 75px;
    @include pc {
      padding: 105px 0;
    }
  }

  &--heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 46px;

    &--main {
      color: $main-color;
      font-family: $alphabet-font;
      font-size: 27px;
      font-weight: 500;
      @include pc {
        font-size: 36px;
      }
    }

    &--sub {
      color: #333;
      font-size: 12px;
      font-weight: normal;
      @include pc {
        font-size: 15px;
      }
    }
  }

  &--footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
  }
}
