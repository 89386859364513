.p-single-3 {
  padding-bottom: 59px;

  @include pc {
    padding-bottom: 96px;
  }

  .l-container {
    // @include pc {
    //   @include container-max-width(690px);
    // }
  }

  &--main-contents {
    // margin-bottom: 48px;
    // @include pc {
    //   margin-bottom: 96px;
    // }
  }

  &--header--info,
  &--content {
    @include pc {
      margin: 0 auto;
      // padding-right: 4%;
      // padding-left: 4%;
    }
  }
  &--header-wrapper {
    margin-bottom: 59px;
    @include pc {
      margin-bottom: 96px;
    }
  }
  &--heading {
    // padding-bottom: 8px;
    margin-bottom: 12px;
    // border-bottom: 2px solid #000;
    color: $main-color;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 1.26;

    @include pc {
      font-size: 36px;
      line-height: 1.4;
      // padding-bottom: 15px;
      margin-bottom: 15px;
    }
  }
  &--header--info {
    // @include tab {
    //   padding: 0 13px;
    // }
    // @include pc {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 20px;
    // }
  }
  &--category--list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
  }
  &--category--item {
    border-radius: 10px;
    background: $main-color;
    // margin-bottom: 10px;
    &:not(:last-of-type) {
      margin-right: 10px;
    }
    a {
      display: block;
      padding: 9px 20px;
      width: 100%;
      height: 100%;
      font-size: 10px;
      color: #fff;
      letter-spacing: 0.05em;
      line-height: 1.2;
      text-decoration: none;
      @include pc {
        font-size: 16px;
      }
    }
  }
  &--date--list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  &--date--item {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.05em;
    @include pc {
      font-size: 17px;
    }

    &:not(:last-of-type) {
      padding-right: 13px;
    }
  }

  &--content {
    *:last-child {
      margin-bottom: 0;
    }
    figure {
      width: 100%;
      max-width: 880px;
      margin: 0 auto 59px;
      @include pc {
        margin-bottom: 96px;
      }
    }
    p {
      // margin: 0 auto 25px;
      // padding: 0 10px;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0.05em;
      line-height: 1.54;
      margin-bottom: 25px;
      @include pc {
        font-size: 14px;
        line-height: 2.14;
        margin-bottom: 40px;
        padding: 0;
        // width: 94.5%;
      }
    }
    h2 {
      margin-bottom: 20px;
      padding: 5px 8px;
      border-left: 2px solid $main-color;
      background: #e8e8e8;
      font-size: 2.2rem;
      font-weight: 700;
      letter-spacing: 0.05em;
      line-height: 1.4;
      @include pc {
        margin-bottom: 40px;
        padding: 5px 18px;
      }
    }
    h3 {
      display: flex;
      margin-bottom: 20px;
      font-size: 2.2rem;
      letter-spacing: 0.05em;
      line-height: 1.4;
      @include pc {
        margin-bottom: 40px;
      }
      &::before {
        margin-top: 11px;
        margin-right: 7px;
        content: "";
        display: block;
        width: 100%;
        max-width: 17px;
        height: 2px;
        background: $main-color;
      }
    }

    .wp-block-embed-youtube {
      height: 0;
      overflow: hidden;
      padding-top: 30px;
      padding-bottom: 56.25%;
      position: relative;

      .wp-block-embed__wrapper {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      iframe,
      object,
      embed {
        height: 100%;
        width: 100%;
      }
    }
  }
  &--button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 19px 0;
    border: 1px solid #dddddd;
    border-radius: 3px;
    color: inherit;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-align: center;
    text-decoration: none;
    &::before {
      content: "";
      display: block;
      width: 6px;
      height: 6px;
      margin-right: 14px;
      border-bottom: 2px solid #000;
      border-left: 2px solid #000;
      transform: rotate(45deg);
    }
  }
}
