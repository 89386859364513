.p-top-our-results {
  position: relative;
  background: url("../img/top/our-results/bg--top--our-results.jpg") no-repeat
    center center / cover;

  > .l-container-top {
    width: 80%;
    @include pc {
      width: 100%;
    }
  }

  &--inner {
    padding: 60px 0 75px;
    @include pc {
      padding: 105px 0;
    }
  }

  .l-sidetitle {
    left: -8%;
    @include pc {
      left: 2%;
    }

    &--heading {
      &--main {
        color: #fff;
      }
      &--prefix {
        background: #fff;
      }
    }
  }

  &--heading {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 36px;
    @include pc {
      margin-bottom: 105px;
    }

    &--main {
      color: #fff;
      font-family: $alphabet-font;
      font-size: 27px;
      font-weight: 500;
      @include pc {
        font-size: 36px;
      }
    }

    &--sub {
      color: #fff;
      font-size: 12px;
      font-weight: normal;
      @include pc {
        font-size: 15px;
      }
    }
  }

  &--content {
    &--wrapper {
      display: flex;
      flex-direction: column;
      gap: 46px;
      @include pc {
        flex-direction: row;
        gap: 114px;
      }
    }

    &--heading {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 24px;
      @include pc {
        font-size: 23px;
      }
    }

    &--list {
      display: flex;
      flex-direction: column;
      gap: 25px;
      list-style: none;
      padding: 0;
      margin: 0;
      @include pc {
        flex-direction: row;
        gap: 30px;
      }
    }

    &--item {
      width: 100%;
      @include pc {
        max-width: 270px;
      }

      &--image {
        img {
          background: #fff;
          width: 100%;
          height: auto;
          aspect-ratio: 275 /170;
          object-fit: cover;
          @include pc {
            max-height: 160px;
          }
        }
      }

      &--inner {
        background: #f2f2f2;
        padding: 10px;
      }

      &--heading {
        color: #333;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.54;
        text-align: center;
        @include pc {
          font-size: 14px;
        }
      }
    }

    &--footer {
      display: flex;
      justify-content: center;
      margin-top: 12px;
      @include pc {
        margin-top: 30px;
      }
    }
  }
}
