.p-privacy-policy {
  .l-mainvisual {
    background: url("../img/privacy-policy/bg--privacy-policy--sp.jpg")
      no-repeat center center / cover;
    @include pc {
      background-image: url("../img/privacy-policy/bg--privacy-policy--pc.jpg");
    }
  }

  &--contents {
    width: 100%;
    max-width: 830px;
    padding: 59px 0;
    @include pc {
      padding: 96px 0;
    }

    h2 {
      color: $main-color;
      font-size: 22px;
      font-weight: 600;
      line-height: 1.26;
      margin-top: 59px;
      margin-bottom: 46px;
      @include pc {
        font-size: 36px;
        margin-top: 97px;
        margin-bottom: 47px;
      }
    }

    p {
      color: #000;
      font-size: 13px;
      font-weight: normal;
      line-height: 1.54;
      @include pc {
        font-size: 14px;
        line-height: 2.1;
      }
    }
  }
}
