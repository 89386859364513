.c-pagetitle {
    &--mainvisual {
        background: center / cover no-repeat;
        background-image: url(../img/pagetitle/pagetitle_bg.jpg);
        color: #fff;
        position: relative;

        &:before {
            background: rgba(0, 0, 0, .6);
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }

        > .l-container {
            position: relative;
            z-index: 1;
        }

        &--inner {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 60px 0;

            @include pc {
                min-height: 300px;
                padding: 120px 0;
            }
        }
    }
}