.p-top-firstvisual {
  height: 100vh;
  @include pc {
    height: 790px;
  }

  .l-container-top {
    position: relative;
    height: 100%;
  }

  .l-sidetitle {
    bottom: 20px;
    @include pc {
      left: 2%;
    }
  }

  &--inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 42px;
    height: 100%;
    @include pc {
      gap: 50px;
    }
  }

  &--heading {
    color: $main-color;
    font-family: $alphabet-font;
    font-size: 48px;
    font-weight: 500;
    // line-height: 1.208;
    line-height: 1;
    @include pc {
      font-size: 80px;
      // line-height: 1.125;
    }

    &--item {
      display: block;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  &--message {
    color: #333;
    font-size: 15px;
    font-weight: normal;
    @include pc {
      font-size: 30px;
    }

    .-strong {
      color: $main-color;
    }
  }

  &--bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    @include pc {
      width: 50%;
    }
  }
}
