.l-map {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: 80%;
  @include pc {
    padding-top: 32%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
